export const category = [
  {name: "TOUTES CATEGORIES",
    id: 1},
  {name: "ANIMAUX",
    id: 2},
  {name: "ART & PHOTO",
    id: 3},
  {name: "ARTISANAT & CUISINE",
    id: 4},
  {name: "AUTOMOBILE",
    id: 5},
  {name: "BD",
    id: 6},
  {name: "EDITION & JOURNAL.",
    id: 7},
  {name: "ENFANCE & EDUC.",
    id: 8},
  {name: "ENVIRONNEMENT",
    id: 9},
  {name: "FILM & VIDÉO",
    id: 10},
  {name: "JEUX",
    id: 11},
  {name: "MODE & DESIGN",
    id: 12},
  {name: "MUSIQUE",
    id:13},
  {name: "SANTÉ & BIEN-ÊTRE",
    id: 14},
  {name: "SOLIDAIRE & CITOYEN",
    id: 15},
  {name: "SPECTACLE VIVANT",
    id: 16},
  {name: "SPORTS",
    id: 17},
  {name: "TECHNOLOGIE",
    id: 18},
  {name: "AUTRES PROJETS",
    id: 19},

];


export const financingTypes = [
  {title: "Tout type de financement", value : "all"},
  {title: "Investissement par don", value : "dons"},
  {title: "Investissement avec prêt", value : "pret"},
  /* {title: "capital", value : "capital"}, */
];