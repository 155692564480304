export const projectProgressStyles = {
  box1:{ 
    display: "flex", 
    alignItems: "center",
  },
  box2:{ 
    width: "100%", 
    mr: 1 ,
  },
   
}